<template>
  <footer class="pt-1 pb-1 mt-3">
    <b-container class="mt-5 mb-4">
      <b-row>
        <b-col cols="12">
          <ul class="list-inline">
            <li class="list-inline-item footer-xl footer-sm">
              <img
                class="logo"
                alt="Logo Provincie West-Vlaanderen"
                src="/img/logo.png"
              />
            </li>
            <li class="list-inline-item footer-xl footer-sm">
              &copy; {{ new Date() | moment('YYYY') }} Provincie West-Vlaanderen
            </li>
            <li class="list-inline-item footer-lg">
              <img
                class="logo"
                alt="Logo Provincie West-Vlaanderen"
                src="/img/logo.png"
              />
              &copy; {{ new Date() | moment('YYYY') }} Provincie West-Vlaanderen
            </li>
            <li class="list-inline-item pl-2 pr-2 seperator-item footer-xl">
              <div class="seperator"></div>
            </li>
            <li class="list-inline-item ">
              <a href="/" selected>Home</a>
            </li>
            <li class="list-inline-item seperator-item footer-xl pl-1 pr-1">
              <div class="seperator"></div>
            </li>

            <li class="list-inline-item">
              <a
                href="https://www.west-vlaanderen.be/uitleendienst-disclaimer"
                target="_BLANK"
                selected
                >Disclaimer</a
              >
            </li>
            <li class="list-inline-item seperator-item footer-xl pl-1 pr-1">
              <div class="seperator"></div>
            </li>
            <li class="list-inline-item">
              <a
                href="https://www.west-vlaanderen.be/faq-veelgestelde-vragen"
                target="_BLANK"
                >Veelgestelde vragen</a
              >
            </li>
            <li class="list-inline-item seperator-item footer-xl pl-1 pr-1">
              <div class="seperator"></div>
            </li>

            <li>
              <a
                href="https://www.west-vlaanderen.be/uitleendienst-privacy"
                target="_BLANK"
                >Privacy</a
              >
            </li>
            <li class="pl-2 list-inline-item seperator-item footer-xl">
              <div class="seperator"></div>
            </li>

            <li>
              <a
                href="https://www.west-vlaanderen.be/over-west-vlaanderen/contact"
                target="_BLANK"
                >Contact</a
              >
            </li>
            <li class="pl-2 list-inline-item seperator-item footer-xl">
              <div class="seperator"></div>
            </li>
            <li class="list-inline-item">
              <span class="icon-link ml-0 ml-lg-2 mr-3">
                <a
                  href="https://www.facebook.com/uitleendienstwestvlaanderen"
                  target="_BLANK"
                >
                  <font-awesome-icon :icon="['fab', 'facebook-square']" />
                </a>
              </span>

              <span class="icon-link">
                <a href="mailto:uitleendienst@west-vlaanderen.be">
                  <font-awesome-icon icon="envelope" />
                </a>
              </span>
            </li>
            <li class="list-inline-item pl-3 ">
              Tel. 050 40 76 76
            </li>
            <li></li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <cookie-law theme="westvlaanderen" @accept="acceptCookies()">
      <div slot-scope="props" class="w-100">
        <b-container fluid class="pt-4 pb-3">
          <b-row>
            <b-col sm="12" lg="8">
              <p>
                <strong
                  >Wij gebruiken cookies om je ervaring te verbeteren</strong
                ><br />
                Op de site van de uitleendienst worden cookies bijgehouden.
                Functionele cookies dienen om de website goed te laten werken.
                Met analytische cookies verzamelen we informatie over je gebruik
                van de website. Voor die laatste categorie vragen we je
                toestemming. Er worden geen andere cookies verzameld.
              </p>
            </b-col>
            <b-col class="text-center text-lg-right" md="12" lg="4">
              <a
                class="btn btn-outline-light mr-3 mb-lg-3 mb-xl-0"
                href="https://www.west-vlaanderen.be/disclaimer"
                target="BLANK"
                >Meer info</a
              >
              <b-button
                class="mb-lg-3 mb-xl-0"
                variant="outline-light"
                @click="props.accept"
              >
                <span>Ik ga akkoord</span>
              </b-button>
              <b-button
                class="ml-3"
                variant="outline-light"
                @click="props.decline"
              >
                <span>Niet toestaan</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
  components: { CookieLaw },
  methods: {
    acceptCookies: function() {
      this.$store.commit('acceptCookies')
    }
  }
}
</script>
<style scoped lang="scss">
footer {
  .icon-link {
    a {
      color: #6c757d;
      font-size: 20px;
    }
  }
  background-color: white;
  font-family: 'Roboto';

  .footer-xl {
    display: inline-block;
  }

  .footer-lg,
  .footer-xs {
    display: none;
  }

  li {
    display: inline-flex;
    vertical-align: middle;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
  }

  .logo-title-in-one {
    display: none;
  }

  .seperator {
    height: 21px;
    width: 1px;
    background-color: #282828;
  }

  .logo {
    width: 86px;
    height: 36px;
  }
}
@media (max-width: 1200px) {
  footer {
    text-align: center;
    .footer-xl {
      display: none;
    }
    .footer-lg {
      display: block;
    }
  }
}

@media (max-width: 991px) {
  footer {
    .footer-xl {
      display: none;
    }
    .footer-lg {
      display: block;
    }
    .pl-4,
    .pr-3 {
      padding: 0 !important;
    }
    ul {
      li {
        width: 100% !important;
        text-align: center;
        margin: auto;
        display: block;
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 523px) {
  .footer-lg {
    display: none !important;
  }
  .footer-sm {
    display: inline-block !important;
  }
}
</style>
