import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=088a3f05&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=088a3f05&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "088a3f05",
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BContainer, BButton} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BContainer, BButton})
    

export default component.exports